import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { PersonLayout } from '@shapeable/web';

export default createGatsbyPageComponent('Person', { layout: PersonLayout });

export const personQuery = graphql`
  query PersonQuery($id: ID!) {
    platform {
      person(id: $id) {
        id slug name __typename
        bio { text }
        photo { id url }
        position
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        organisation { id name slug }
        pages {
          id name slug path
          openGraph { 
            image { id url } 
          }
        }
        moderatingTopics {
          id name slug outlineNumber path
          color { id name slug value }
          openGraph { 
            image { id url } 
          }
        }
        globalExpertForTopics {
          id name slug outlineNumber path
          color { id name slug value }
          openGraph { 
            image { id url } 
          }
        }
      }
    }
  }
`;
